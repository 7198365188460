import React from "react"
import AuthContextProvider from "./src/context/AuthContextProvider"
import KlassContextProvider from "./src/context/KlassContextProvider"
import StudentContextProvider from "./src/context/StudentContextProvider"
import SWRContextProvider from "./src/context/SWRContextProvider"
import { CookiesProvider } from 'react-cookie'

export const wrapRootElement: React.FC<{element: React.ReactNode}> = ({ element }) => {
    return (
        <SWRContextProvider>
            <CookiesProvider defaultSetOptions={{ domain: process.env.GATSBY_COOKIE_DOMAIN, path: '/', sameSite: 'lax' }}>
                <AuthContextProvider>
                    <KlassContextProvider>
                        <StudentContextProvider>
                            {element}
                        </StudentContextProvider>
                    </KlassContextProvider>
                </AuthContextProvider>
            </CookiesProvider>
        </SWRContextProvider>
    )
}