export type ColorMap = { [key: string]: string };

export const globalColors: ColorMap = {
    white: "#fff",
    black: "#000",
    lightGray: "#eee",
    mediumGray: "#464646",
    purple: "#5e1c84",
    pink: "#c32f96",
    pinkMedium: "#D42DA3",
    pinkLight: "#DF6BBC",
    blue: "#05aeed",
    orange: "#ffb40f",
    orangeMedium: "#FED300",
    orangeLight: "#FFE8A4",
    green: "#61bb46",
    greenMedium: "#5DCC46",
    greenLight: "#A7F897",
    indigo: "#3e4bbe",
    cleverBlue: "#1464FF",
    googleRed: "#e84461",
    creatorBackground: "#192661",
}
