export const globalEndpoints = {
    session: `v1/session`,
    login: `v1/login`,
    simple_register: `v1/simple_register`,
    socialLogin: `v1/social_login`,
    cleverLogin: `v1/clever/login`,
    resetPassword: `v1/reset_password/send`,
    klasses: {
        klassCode: (klassCode: string) => `v2/klasses?klass_code=${klassCode}&include_teacher=true&include_students=true&include_selected_fuzz=true&include_assignments=true`,
        klassCodeFromStudentCode: (studentCode: string) => `v2/klasses/student?student_code=${studentCode}`
    },
    parentSubscription: {
        pricing: `v1/subscriptions/parent-pricing`,
        purchase: (userId: string | number) => `v1/users/${userId}/purchase-parent-subscription`
    },
    accountless: {
        setCredentials: `v1/accountless/set-credentials`,
    },
    stripe: {
        setupIntent: `v1/stripe/setup-intent`,
        paymentIntent: `v1/stripe/payment-intent`,
    },
    analytics: {
        clientEvent: `v1/analytics/client-event`
    }
}