import { globalEndpoints } from '../globals/globalEndpoints'
import React, { useEffect, useState } from "react";
import { IUserProfile } from "../types/IUserProfile"
import { ISession } from "../types/ISession"
import { apiRequest } from '../utils/apiRequest';
import { useCookies } from 'react-cookie';

interface IAuthContext {
  hasSessionToken: boolean;
  session: ISession;
  parentSession: ISession;
  currentUser: IUserProfile;
  setSessionToken: (token: string | null) => void;
  setParentSessionToken: (token: string | null) => void;
  mutate: () => void;
}

const AuthContext = React.createContext<IAuthContext>({} as any);

const AuthContextProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
  const [sessionToken, setSessionToken] = useState<string | null>(null)
  const [sessionData, setSessionData] = useState<ISession | null>(null)
  const [parentSessionData, setParentSessionData] = useState<ISession | null>(null)
  const [parentSessionToken, setParentSessionToken] = useState<string | null>(null)
  const [currentUser, setCurrentUser] = useState<IUserProfile | null>(null)
  const [cookies, setCookie, removeCookie] = useCookies(['kodable_kode']);

  useEffect(() => {
    const kodableKode = cookies?.kodable_kode
    // If the cookie is not set, clear the session token and data
    if (!kodableKode) {
      setSessionToken(null)
      setSessionData(null)
      return
    }
    // Don't change the session token if it already matches cookie
    if (sessionToken === kodableKode) return
    // Otherwise, set the session token to the cookie value
    setSessionToken(kodableKode)
  }, [cookies?.kodable_kode])

  useEffect(() => {
    if (!sessionToken) return
    // Don't request session data if we already have it
    if (sessionData?.session_token === sessionToken && sessionData) return
    // Request the data
    mutate()
  }, [sessionToken])

  useEffect(() => {
    if (!parentSessionToken) return
    // Don't request session data if we already have it
    if (parentSessionData?.session_token === parentSessionToken && parentSessionData) return
    // Request the data
    mutateParent()
  }, [parentSessionToken])

  useEffect(() => {
    if (!sessionData) return
    setCurrentUser({
      ...sessionData?.user,
      is_accountless: sessionData?.user?.username.includes('@accountless.kodable.com')
    })
  }, [sessionData])

  const mutate = () => {
    apiRequest(globalEndpoints?.session, 'GET')
    .then(async (res) => {
      if (res.ok) {
        const data = await res.json()
        setSessionData(data)
        setSessionTokenInternal(data?.session_token)
        return
      }

      throw new Error('Could not get session data for your account')
    })
    .catch((error) => {
      console.log(error)
    })
  }

  const mutateParent = () => {
    apiRequest(globalEndpoints?.session, 'GET', undefined, parentSessionToken)
    .then(async (res) => {
      if (res.ok) {
        const data = await res.json()
        setParentSessionData(data)
        setParentSessionTokenInternal(data?.session_token)
        return
      }

      throw new Error('Could not get session data for your parent account')
    })
    .catch((error) => {
      console.log(error)
    })
  }

  const setSessionTokenInternal = (token: string | null) => {
    if (!token) {
      removeCookie('kodable_kode')
      setSessionToken(null)
      return
    }
    setSessionToken(token)
    if (cookies?.kodable_kode === token) return
    setCookie('kodable_kode', token)
  }

  const setParentSessionTokenInternal = (token: string | null) => {
    if (!token) {
      setParentSessionToken(null)
      return
    }
    setParentSessionToken(token)
  }

  const authValue = {
    hasSessionToken: cookies?.kodable_kode,
    session: sessionData! && sessionData,
    parentSession: parentSessionData! && parentSessionData,
    currentUser: currentUser! && currentUser,
    setSessionToken: setSessionTokenInternal,
    setParentSessionToken: setParentSessionTokenInternal,
    mutate,
    mutateParent
  }

  return <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>;
}

export default AuthContextProvider;

export const useAuthContext = () => {
  return React.useContext(AuthContext);
}