import React, { createContext, useContext, useEffect, useState } from 'react';
import { IStudent } from '../types/IStudent';
import { useKlassContext } from './KlassContextProvider';
import { apiRequest } from '../utils/apiRequest';
import { globalEndpoints } from '../globals/globalEndpoints';
import { IKlassCodeFromStudentCodeResponse } from '../types/IKlassCodeFromStudentCodeResponse';
import { useAuthContext } from './AuthContextProvider';

interface IStudentContext {
    student: IStudent | null;
    setStudent: React.Dispatch<React.SetStateAction<IStudent | null>>;
    studentCode: string | null;
    setStudentCode: (studentCode: string | null) => void;
    studentError: string | null;
    setStudentError: React.Dispatch<React.SetStateAction<string | null>>;
}

const StudentContext = createContext<IStudentContext>({
    student: null,
    setStudent: () => {},
    studentCode: null,
    setStudentCode: () => {},
    studentError: null,
    setStudentError: () => {}
})

export const useStudentContext = () => {
    const { student, setStudent, studentCode, setStudentCode, studentError, setStudentError } = useContext(StudentContext)
    return { student, setStudent, studentCode, setStudentCode, studentError, setStudentError }
}

const StudentContextProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
    const { currentUser } = useAuthContext()
    const { klass, setKlassCode } = useKlassContext()
    const [student, setStudent] = useState<IStudent | null>(null)
    const [studentCode, setStudentCodeInState] = useState<string | null>(null)
    const [studentError, setStudentError] = useState<string | null>(null)

    const setStudentCode = (studentCode: string | null) => {
        if (studentCode) {
            studentCode = studentCode.replace('_pi', '');
        }
        setStudentCodeInState(studentCode);
    };

    // Handles setting the student code to "n" for logged-in users who are parents
    useEffect(() => {
        if (!currentUser) return
        if (!currentUser?.is_parent) return
        setStudentCode('n')
    }, [currentUser])

    useEffect(() => {
        // Skip making request when student code is not set
        if (!studentCode) return
        // Skip making request when student code is 'n', this is a parent account student
        if (studentCode === 'n') return
        
        // Set the student's data from the klass data
        if (klass && !student) {
            const klass_student = klass?.students?.find((klass_student) => klass_student.student_code === studentCode)
            if (klass_student) {
                setStudent(klass_student)
            }
        }

        // Skip making request when klass is already set, and the student klass_id matches the klass id
        if (klass && student && klass.id === student?.klass_id) return
        
        // Get the student's klass code so we can allow the KlassContextProvider to make a request to get the klass 
        // in a standardized way
        apiRequest(globalEndpoints?.klasses?.klassCodeFromStudentCode(studentCode), 'GET')
        .then(async(res) => {
            if (res.ok) {
                const data = await res.json() as IKlassCodeFromStudentCodeResponse
                const klassCodeLowercase = data?.klass_code_lowercase
                setKlassCode(klassCodeLowercase)
                return
            }
            const error = await res.json()

            throw new Error(error?.message ? error.message : 'Could not find class using student code')
        })
        .catch((error) => {
            setStudentError(error?.message)
        })
    }, [studentCode, klass, student])
    

    return (
        <StudentContext.Provider value={{ student, setStudent, studentCode, setStudentCode, studentError, setStudentError }}>
            {children}
        </StudentContext.Provider>
    )
}

export default StudentContextProvider;
