exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-basics-tsx": () => import("./../../../src/pages/basics.tsx" /* webpackChunkName: "component---src-pages-basics-tsx" */),
  "component---src-pages-class-klass-code-tsx": () => import("./../../../src/pages/class/[klass_code].tsx" /* webpackChunkName: "component---src-pages-class-klass-code-tsx" */),
  "component---src-pages-clever-tsx": () => import("./../../../src/pages/clever.tsx" /* webpackChunkName: "component---src-pages-clever-tsx" */),
  "component---src-pages-creator-tsx": () => import("./../../../src/pages/creator.tsx" /* webpackChunkName: "component---src-pages-creator-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-play-at-home-tsx": () => import("./../../../src/pages/play-at-home.tsx" /* webpackChunkName: "component---src-pages-play-at-home-tsx" */),
  "component---src-pages-play-at-school-tsx": () => import("./../../../src/pages/play-at-school.tsx" /* webpackChunkName: "component---src-pages-play-at-school-tsx" */),
  "component---src-pages-select-game-tsx": () => import("./../../../src/pages/select-game.tsx" /* webpackChunkName: "component---src-pages-select-game-tsx" */),
  "component---src-pages-teacher-demo-tsx": () => import("./../../../src/pages/teacher-demo.tsx" /* webpackChunkName: "component---src-pages-teacher-demo-tsx" */)
}

