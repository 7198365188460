import * as Sentry from "@sentry/gatsby";

Sentry.init({
  dsn: "https://12afe8e57a963533351316d371139c07@o259245.ingest.sentry.io/4506759058030592",
  integrations: [Sentry.replayIntegration()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.01,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 0.0,
})