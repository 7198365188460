import { Interpolation, Theme, createTheme, ButtonClassKey } from "@mui/material";
import { globalColors } from "../globals/globalColors";
import { ButtonProps as MuiButtonProps } from "@mui/material";

interface ButtonVariant {
    props: Partial<ButtonProps>;
    style: Interpolation<{ theme: Omit<Theme, 'components'> }>;
}

declare module "@mui/material/Button" {
    interface ButtonPropsSizeOverrides {
        extraLarge: true;
    }
}

declare module '@mui/material/Button/ButtonClasses' {
    interface ButtonClasses {
        sizeExtraLarge?: string;
    }
}

type ButtonProps = MuiButtonProps & {
    size?: MuiButtonProps['size'] | 'extraLarge';
}

const createOutlinedVariant = (colorName: string, colorCode: string): ButtonVariant => ({
    props: { variant: 'outlined', color: colorName as ButtonProps['color'] },
    style: {
        backgroundColor: globalColors?.white,
        color: colorCode,
        border: `2px solid ${colorCode} !important`,

        '&:hover': {
            backgroundColor: globalColors?.lightGray,
            border: `2px solid ${colorCode} !important`
        }
    },
});

const outlinedVariants = Object.keys(globalColors).map((colorName): ButtonVariant => createOutlinedVariant(colorName, globalColors[colorName]))

declare module "@mui/material/styles" {
    interface Palette {
        purple: Palette['primary'];
        blue: Palette['primary'];
        orange: Palette['primary'];
        pink: Palette['primary'];
        green: Palette['primary'];
        black: Palette['primary'];
        white: Palette['primary'];
        cleverBlue: Palette['primary'];
        googleRed: Palette['primary'];
        mediumGray: Palette['primary'];
    }

    interface PaletteOptions {
        purple: PaletteOptions['primary'];
        blue: PaletteOptions['primary'];
        orange: PaletteOptions['primary'];
        pink: PaletteOptions['primary'];
        green: PaletteOptions['primary'];
        black: PaletteOptions['primary'];
        white: PaletteOptions['primary'];
        cleverBlue: PaletteOptions['primary'];
        googleRed: PaletteOptions['primary'];
        mediumGray: PaletteOptions['primary'];
    }
}

let theme = createTheme({
    palette: {
        purple: {
            main: globalColors?.purple,
            light: globalColors?.purple,
            dark: globalColors?.purple,
            contrastText: globalColors?.white,
        },
        blue: {
            main: globalColors?.blue,
            light: globalColors?.blue,
            dark: globalColors?.blue,
            contrastText: globalColors?.white,
        },
        orange: {
            main: globalColors?.orange,
            light: globalColors?.orangeLight,
            dark: globalColors?.orangeMedium,
            contrastText: globalColors?.white
        },
        pink: {
            main: globalColors?.pink,
            light: globalColors?.pinkLight,
            dark: globalColors?.pinkMedium,
            contrastText: globalColors?.white
        },
        green: {
            main: globalColors?.green,
            light: globalColors?.greenLight,
            dark: globalColors?.greenMedium,
            contrastText: globalColors?.white
        },
        black: {
            main: globalColors?.black,
            light: globalColors?.black,
            dark: globalColors?.black,
            contrastText: globalColors?.white
        },
        white: {
            main: globalColors?.white,
            light: globalColors?.white,
            dark: globalColors?.white,
            contrastText: globalColors?.black
        },
        cleverBlue: {
            main: globalColors?.cleverBlue,
            light: globalColors?.cleverBlue,
            dark: globalColors?.cleverBlue,
            contrastText: globalColors?.white
        },
        googleRed: {
            main: globalColors?.googleRed,
            light: globalColors?.googleRed,
            dark: globalColors?.googleRed,
            contrastText: globalColors?.white
        },
        mediumGray: {
            main: globalColors?.mediumGray,
            light: globalColors?.mediumGray,
            dark: globalColors?.mediumGray,
            contrastText: globalColors?.white
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                a: {
                    color: globalColors?.mediumGray
                },
                body: {
                    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue",sans-serif'
                }
            }
        },
        MuiButton: {
            variants: {...outlinedVariants, ...{
                props: { size: 'extraLarge' }, // Extra large button size variant
                style: {
                    fontSize: '2rem',
                    padding: '.25rem 4rem',
                    borderRadius: '32px'
                },
            }},
            defaultProps: {
                disableElevation: true // This removes box shadows on buttons by default
            },
            styleOverrides: {
                root: {
                    borderRadius: 28, // This makes all buttons rounded by default
                    '& .MuiButton-startIcon': { // This sets rules for the height and width of the startIcon
                        height: 16,
                        
                        '& > *': { // Element of any type in the startIcon
                            height: 'inherit',
                            width: 'auto'
                        }
                    },
                    '& .MuiButton-endIcon': { // This sets rules for the height and width of the endIcon
                        height: 16,
                        
                        '& > *': { // Element of any type in the endIcon
                            height: 'inherit',
                            width: 'auto'
                        }
                    },
                },
                sizeExtraLarge: {
                    fontSize: '2rem',
                    padding: '.25rem 4rem',
                    borderRadius: '32px'
                },
            }, 
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        borderRadius: 28, // This makes all text fields rounded by default
                    },
                    '& .MuiInputLabel-shrink': {
                        paddingLeft: 'calc(1.5rem - 2px)', // Adjust the Label to account for rounding
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        paddingLeft: '1.5rem', // Adjust the NotchedOutline to account for rounding
                    },
                },
            }
        },
        MuiTypography: {
            variants: [
                {
                    props: { color: 'white' },
                    style: {
                        color: globalColors?.white
                    },
                }
            ],
            styleOverrides: {
                h1: {
                    fontFamily: 'Bjola, sans-serif'
                },
                h2: {
                    fontFamily: 'Bjola, sans-serif'
                },
                h3: {
                    fontFamily: 'Bjola, sans-serif'
                },
                body1: {
                    color: globalColors?.mediumGray
                },
                body2: {
                    color: globalColors?.mediumGray
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: globalColors?.lightGray,
                    width: '100%'
                }
            }
        },
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    color: globalColors?.blue
                }
            }
        }
    },
})

export default theme;