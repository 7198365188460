import Cookies from 'universal-cookie';

export const apiRequest = (path: string, method: string, body: {} | undefined = undefined, token_override: string | null | undefined = undefined) => {
    const endpoint = `${process.env.GATSBY_API_ENDPOINT}${!path.startsWith('/') ? '/' : ''}${path}`;
    const cookies = new Cookies();

    return fetch(endpoint, {
        method: method,
        body: body ? JSON.stringify(body) : undefined,
        headers: {
            'KODABLE-API-TOKEN': token_override || cookies.get('kodable_kode'),
            'Content-Type': 'application/json;charset=UTF-8',
        },
    })
}
  