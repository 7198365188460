
import React from 'react';
import { useCookies } from 'react-cookie';
import { SWRConfig } from 'swr';

const SWRContextProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies(['kodable_kode']);
  const swrConfig = {
    revalidateOnFocus: false,
    fetcher: (url: string) => {
      return fetch(`${process.env.GATSBY_API_ENDPOINT}/${url}`, {
        headers: { 'KODABLE-API-TOKEN': cookies?.kodable_kode }
      })
        .then(async (res) => {
          if (!res.ok) {
            throw await res.json();
          }

          return res.json();
        })
    }
  }

  return <SWRConfig value={swrConfig}>{children}</SWRConfig>
}

export default SWRContextProvider;